import React, { Fragment, useEffect } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CANChatLogo from '../images/applogo.png';
import Phone01 from '../images/phone-01e.jpeg';
import Phone01Full from '../images/phone-01.jpg';
import Phone02 from '../images/phone-02.jpg';
import Phone03 from '../images/phone-03e.jpeg';
import Phone05 from '../images/phone-05e.jpeg';
import Phone05ee from '../images/phone-05ee.jpeg'
import Phone06 from '../images/phone-06e.jpeg';
import Phone07 from '../images/phone-07e.jpeg';
import { BackArrow, NextArrow } from '../components/Arrows'
import useWindowSize from '../components/WindowSize';

import './HomePage.css';

const HomePage = () => {
    const [width] = useWindowSize();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        nextArrow: <NextArrow />,
        prevArrow: <BackArrow />
    };

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, []);

    const getDescriptions = () => {
        if (width > 768) {
            return (
        <Slider {...settings}>
            <div className='home-page-app-descriptions-section section-1'>
                <div className='home-page-app-descriptions-overlay'></div>
                <div className='home-page-app-description-text'>
                    The app allows you to chat with large language AI models, generate or edit images.  It also let you upload images for the model to interpret. 
                    The app has a limit on each function.  Users can buy limit packages to increase the limits.   
                    However, the app comes with some free limits for you to try out the app.  
                </div>
                <img className="home-page-description-screenshot" src={Phone06} alt="Phone Screenshot 6" />
            </div>
            <div className='home-page-app-descriptions-section section-2'>
                <div className='home-page-app-descriptions-overlay'></div>
                <div className='home-page-app-description-text'>
                    You can chat with the AI models by text.  We offer two models for you to choose, general or advance.  
                    There are some chat options you can set, like around how many words you want it to generate.  
                    The level of creativeness you want it to be. 
                    The chat tone.  
                    You can tell the GPT models what you want too.  But the app makes it more convenient.
                </div>
                
                <img className="home-page-description-screenshot" src={Phone01} alt="Phone Screenshot 6" />
            </div>
            <div className='home-page-app-descriptions-section section-3'>
                <div className='home-page-app-descriptions-overlay'></div>
                <div className='home-page-app-description-text'>
                    You can also generate images by giving a text prompt.  
                    There are some options for you to choose, like size, style, form,    
                </div>
                
                <img className="home-page-description-screenshot" src={Phone03} alt="Phone Screenshot 6" />
            </div>
            <div className='home-page-app-descriptions-section section-4'>
                <div className='home-page-app-descriptions-overlay'></div>
                <div className='home-page-app-description-text'>
                    You can edit images too.  Just upload the image, circle the area to be edited and describe how it should be edited.
                </div>
                
                <img className="home-page-description-screenshot" src={Phone05} alt="Phone Screenshot 6" />
            </div>
            <div className='home-page-app-descriptions-section section-5'>
                <div className='home-page-app-descriptions-overlay'></div>
                <div className='home-page-app-description-text'>
                    Under Vision, you can upload your image for the model to interpret.  
                    You can its understanding of the image. 
                </div>
                
                <img className="home-page-description-screenshot" src={Phone07} alt="Phone Screenshot 6" />
            </div>
        
        </Slider>)
        
        } else {
            return (
                //<Slider {...settings}>
                <div className='home-page-app-description-container'>
                    <div className='home-page-app-descriptions-section section-1'>
                        <div className='home-page-app-descriptions-overlay'></div>
                        <img className="home-page-description-screenshot" src={Phone06} alt="Phone Screenshot 6" />
                        <div className='home-page-app-description-text'>
                        The app allows you to chat with large language AI models, generate or edit images.  It also let you upload images for the model to interpret. 
                        The app has a limit on each function.  Users can buy limit packages to increase the limits.   
                        However, the app comes with some free limits for you to try out the app. 
                        </div>
                    </div>
                    <div className='home-page-app-descriptions-section section-2'>
                        <div className='home-page-app-descriptions-overlay'></div>
                        <img className="home-page-description-screenshot" src={Phone01} alt="Phone Screenshot 1" />
                        <div className='home-page-app-description-text'>
                        You can chat with the AI models by text.  We offer two models for you to choose, general or advance.  
                        There are some chat options you can set, like around how many words you want it to generate.  
                        The level of creativeness you want it to be. 
                        The chat tone.  
                        You can tell the models what you want too.  But the app makes it more convenient.
                        </div>
                    <div className='home-page-app-descriptions-section section-3'>
                        <div className='home-page-app-descriptions-overlay'></div>
                        <img className="home-page-description-screenshot" src={Phone03} alt="Phone Screenshot 3" />
                        <div className='home-page-app-description-text'>
                            You can also generate images by giving a text prompt.  
                            There are some options for you to choose, like size, style, form   
                        </div>
                        </div>
                    </div>
                    <div className='home-page-app-descriptions-section section-4'>
                    <img className="home-page-description-screenshot" src={Phone05} alt="Phone Screenshot 5" />
                        <div className='home-page-app-descriptions-overlay'></div>
                        <div className='home-page-app-description-text'>
                            You can edit images too.  Just upload the image, circle the area to be edited and describe how it should be edited.
                        </div>
                    </div>
                    <div className='home-page-app-descriptions-section section-5'>
                        <div className='home-page-app-descriptions-overlay'></div>
                        <img className="home-page-description-screenshot" src={Phone07} alt="Phone Screenshot 6" />
                        <div className='home-page-app-description-text'>
                            Under Vision, you can upload your image for the model to interpret.  
                            You can its understanding of the image. 
                        </div>
                    </div>
                </div>
                //</Slider>
            )
        }
    } 

    return ( 
        <Fragment>
            <div className='page home-page'>
                <div className='home-page-intro'>
                {width > 768 && (
                    <div className="home-page-screenshots home-page-screenshots-left">
                        <img className="home-page-screenshot home-page-screenshot-left" src={Phone01} alt="Phone Screenshot 1" />
                        
                    </div>
                )}
                    <div className="home-page-text-content">
                        <div className='home-page-title'>
                            <h1>Welcome to CAN Chat!</h1>
                        </div>
                        
                        <img className="home-page-logo" src={CANChatLogo} alt="CAN Chat AI Logo" style={{ width: '100px', height: '100px' }} />
                            
                        <div className='home-page-description' >
                            It is an app that let users to chat with large language models.  
                            The app also let users generate and edit images.  
                            Besides, users can chat about the the model's understanding of the images.
                              
                        </div>
                    </div>
                {width > 768 && (
                <div className="home-page-screenshots home-page-screenshots-right">
                    <img className="home-page-screenshot home-page-screenshot-left" src={Phone05ee} alt="Phone Screenshot 1" />
                    
                </div>
                )}
                </div>    
                
                <div className='home-page-app-descriptions'>
                    {getDescriptions()}
                </div>
            </div>
        </Fragment>
    )
}

export default HomePage;

/*
return ( 
        <Fragment>
            <div className='page home-page'>
                <div className='home-page-intro'>
                {width > 768 && (
                    <div className="home-page-screenshots home-page-screenshots-left">
                        <img className="home-page-screenshot home-page-screenshot-left" src={Phone01Full} alt="Phone Screenshot 1" />
                        <img className="home-page-screenshot home-page-screenshot-overlap home-page-screenshot-right" src={Phone02} alt="Phone Screenshot 2" />
                    </div>
                )}
                    <div className="home-page-text-content">
                        <div className='home-page-title'>
                            <h1>Welcome to CAN Chat AI!</h1>
                        </div>
                        
                        <img className="home-page-logo" src={CANChatLogo} alt="CAN Chat AI Logo" style={{ width: '100px', height: '100px' }} />
                            
                        <div className='home-page-description' >
                            It is an app that let users to chat with ChatGPT.  
                            The app also let users generate and edit images.  
                            Besides, users can chat about the ChatGPT 4o's understanding of the images.
                              
                        </div>
                    </div>
                {width > 768 && (
                <div className="home-page-screenshots home-page-screenshots-right">
                    <img className="home-page-screenshot home-page-screenshot-left" src={Phone01Full} alt="Phone Screenshot 1" />
                    <img className="home-page-screenshot home-page-screenshot-overlap home-page-screenshot-right" src={Phone02} alt="Phone Screenshot 2" />
                </div>
                )}
                </div>    
                
                <div className='home-page-app-descriptions'>
                    {getDescriptions()}
                </div>
            </div>
        </Fragment>
    )
*/
