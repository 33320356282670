
import React, { Fragment, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';

import './DataPrivacy.css';
import firebaseClient from '../firebase/FirebaseClient';
import { recaptchaSiteKey } from '../utils/Constants';
import SubmitButton from '../components/SubmitButton'
import OverlayText from '../components/OverlayText';
import './DataPrivacy.css';


const DataPrivacy = () => {
    const [formData, setFormData] = useState({
        email: '',
        requestType: '',
        additionalInfo: ''
    });
    
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const [buttonText, setButtonText] = useState('Submit Request');
    const [isLoading, setIsLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [contentText, setContentText] = useState("Processing...")
    const [statusMessage, setStatusMessage] = useState('');
    const [statusType, setStatusType] = useState('');
    
    const handleChange = (e) => {
        setFormData({
        ...formData,
        [e.target.name]: e.target.value
        });
    };
    
    // before sending the request, we require users to solve a puzzle
    const handleSubmit = async (e) => {
        e.preventDefault();
        /*
        if (!recaptchaToken) {
            //alert('Please complete the reCAPTCHA.');
            setStatusMessage('Please complete the reCAPTCHA.');
            setStatusType('error');
            return;
            */
        if (!formData.email) {
            //alert('Please enter your email.');
            setStatusMessage('Please enter your email.');
            setStatusType('error');
            return;
        } else if (!formData.requestType) {
            //alert('Please choose a request type.');
            setStatusMessage('Please choose a request type.');
            setStatusType('error');
            return;
        }

        setIsLoading(true)

        console.log(`Form data - email: ${formData.email}`)
        console.log(`Form data - request type: ${formData.requestType}`)
        console.log(`Form data - additional info: ${formData.additionalInfo}`)
        
        const { success, message } = 
            await firebaseClient.sendRequest(formData.email, formData.requestType, formData.additionalInfo, "");
        
        setIsLoading(false)
        setIsDone(true)

        if (success) {
            setButtonText("Done")
            setFormData({email: "", requestType: ""})
            setContentText("Request sent successfully")
            setStatusMessage("Request sent successfully, you will received a confirmation email.")
            setStatusType("success")
        } else {
            setButtonText("Failed")
            setContentText("Request not sent")
        }
        
       // alert(message)
    };

    const resetForm = () => {
        setFormData(
            { 
                email: "",
                requestType: "",
                additionalInfo: ""
            }
        )
        setContentText("")
        setIsLoading(false)
        setIsDone(false)
        setButtonText("Submit Request")
        setStatusMessage("")
        setStatusType("")
    }

    const resetStatus = () => {
        setStatusMessage("")
        setStatusType("")
    }

    return ( 
        <Fragment>
            <div className="page data-privacy-page">
                
                <div className="data-privacy-text-content">
                    <form className="data-privacy-form" onSubmit={handleSubmit}>
                        {isLoading && <div className='data-privacy-overlay'><OverlayText content={contentText} /></div>}
                        <h2>Request Data Deletion</h2>
                        
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <div className="request-options">
                            <select
                                id="request"
                                name="requestType"
                                value={formData.requestType}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select a request type</option>
                                <option value="deleteAccount">Delete Account</option>
                                <option value="deleteData">Delete Data</option>
                                <option value="deleteBoth">Delete Both</option>
                            </select>
                        </div>
                        <br/>
                        <div className="additional-info">
                            <label htmlFor="additionalInfo">Additional Information</label>
                            <textarea
                                id="additionalInfo"
                                name="additionalInfo"
                                value={formData.additionalInfo}
                                onChange={handleChange}
                                rows="4"
                            />
                        </div>
                        
                        
                        <div className={`data-privacy-status-message ${statusType ? statusType : 'no-status'}`}>
                            {statusMessage &&  (
                                <>
                                    <span >{statusMessage}</span>
                                    <button 
                                        className='data-privacy-ok-button'
                                        onClick={ statusType == "success" ? resetForm : resetStatus }
                                        
                                    >OK</button>
                                </>
                            )}
                            </div>
                    
                        <SubmitButton isLoading={isLoading} isDone={isDone} buttonText={buttonText}/>
                    </form>
            </div>
                
            </div>
        </Fragment>
    )
}

export default DataPrivacy;

/*
<label htmlFor="request">Request</label>
<label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />

<div className="recaptcha">
                            <ReCAPTCHA
                                sitekey={recaptchaSiteKey}
                                onChange={(token) => setRecaptchaToken(token)} 
                            />

*/